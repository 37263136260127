<template>
  <div>
    <h1>Search Insights</h1>
    <q-banner class="text-primary">
      This section gives insights into the search requests done on
      Historiana.<br />
      Currently only the searches done in the Partner pages are recorded, more
      can be added.
    </q-banner>

    <q-table
      title="Insights"
      :data="entries"
      :columns="columns"
      :pagination="{ rowsPerPage: 50 }"
      :dense="true"
    />
  </div>
</template>
<script>
import { API } from "@/tic";
import { humanDate } from "../tic.js";

export default {
  name: "search-insights",
  data() {
    return {
      entries: [],
      columns: [
        {
          name: "timestamp",
          label: "timestamp",
          align: "left",
          field: (row) =>
            new Date(row.timestamp).toLocaleString({ timeZone: "UTC" }),
          format: (val) => `${val}`,
          sortable: true,
        },
        {
          name: "section",
          label: "section",
          align: "left",
          field: (row) => row.section,
          format: (val) => `${val}`,
          sortable: true,
        },
        {
          name: "context",
          label: "context",
          align: "left",
          field: "context",
          sortable: true,
        },
        {
          name: "searchstring",
          label: "searchstring",
          align: "left",
          field: "searchstring",
          sortable: true,
        },
        {
          name: "results",
          label: "results",
          align: "left",
          field: "results",
          sortable: true,
        },
      ],
    };
  },

  mounted() {
    API.get("/search-insights").then((r) => {
      this.entries = [...r.data.data];
    });
  },
};
</script>